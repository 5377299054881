import Image from 'next/image'
import { FaTimesCircle } from 'react-icons/fa'

import CustomText, { FontWeight } from '@/components/base/CustomText'
import Divider from '@/components/base/Divider'
import { PostVoteCountSection } from '@/components/pages/groups/PostVoteCountSection'
import useRequest2 from '@/hooks/useRequest2'
import TokenFront from '@/public/static/images/token-front.png'
import { getGroupExchangeVotes } from '@/services/groupServices'

export const GroupExchangeRejected = ({ addon }) => {
  const exchangeName = addon.RewardName
  const amount = addon?.TokensAmount ?? 0

  const groupRewardUUID = addon.GroupRewardUUID

  const [votes] = useRequest2({
    requestKey: `/exchange/votes/${groupRewardUUID}`,
    params: [groupRewardUUID],
    request: (groupRewardUUID) => getGroupExchangeVotes(groupRewardUUID),
  })

  return (
    <div className="bg-white border border-gray-200 rounded-2xl p-2 my-4 flex flex-col gap-4">
      <div className=" flex items-center gap-3">
        <div className="w-[50px] h-[50px] sm:w-[60px] sm:h-[60px]">
          <Image
            className="rounded-xl h-full object-cover w-full"
            src={addon?.Image}
            width={100}
            height={100}
            alt="Exchange"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <FaTimesCircle className="text-red" />
            <CustomText weight={FontWeight.SemiBold}>{exchangeName}</CustomText>
          </div>
          <div className="flex items-center gap-2">
            <Image width={18} height={18} src={TokenFront} alt="SNT" />
            <CustomText weight={FontWeight.SemiBold}>
              {amount} <span className="font-normal text-sm">SNT</span>
            </CustomText>
          </div>
        </div>
      </div>
      <Divider />
      <div className="px-2">
        <PostVoteCountSection votes={votes} />
      </div>
    </div>
  )
}
