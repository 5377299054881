import { useRouter } from 'next/router'
import { useState } from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { HiEye } from 'react-icons/hi2'
import { MdHowToVote } from 'react-icons/md'

import Button from '@/components/base/Button'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { FancyModal } from '@/components/base/FancyModal'
import Avatar from '@/components/shared/Avatar'
import { getAuthorProfile } from '@/utils/authors'

export const PostVoteCountSection = ({ votes }) => {
  const [open, setOpen] = useState(false)
  const openModal = () => {
    setOpen(true)
  }
  let trueCount = 0
  let falseCount = 0

  votes &&
    votes?.forEach((obj) => {
      if (obj.IsPositive) {
        trueCount++
      } else {
        falseCount++
      }
    })

  const hasVotes = votes?.length > 0

  return (
    <>
      <section className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-3 text-primary">
            <FaCheck />
            <CustomText weight={FontWeight.SemiBold}>A favor: {trueCount}</CustomText>
          </div>
          <div className="flex items-center gap-3 text-red">
            <FaTimes /> <CustomText weight={FontWeight.SemiBold}>En contra: {falseCount}</CustomText>
          </div>
        </div>
        <Button onClick={openModal} className="p-1 rounded-lg">
          <HiEye size={18} />
        </Button>
      </section>
      <FancyModal title="Votos" isOpen={open} setIsOpen={setOpen}>
        <div className="flex flex-col gap-2 max-h-[400px] h-[400px] overflow-y-scroll px-4">
          {hasVotes && votes.map((item) => <VoteItem key={item.UUID} item={item} />)}

          {!hasVotes && (
            <div className="flex flex-col items-center gap-2">
              <MdHowToVote size={32} />
              <CustomText className="text-black" weight={FontWeight.SemiBold}>
                Nadie ha votado aún
              </CustomText>
            </div>
          )}
        </div>
      </FancyModal>
    </>
  )
}

const VoteItem = ({ item }) => {
  const { push } = useRouter()
  const likeAuthor = item.Included.Author

  const isPositive = item.IsPositive

  const handleRedirect = () => {
    push(getAuthorProfile(likeAuthor))
  }

  return (
    <button onClick={handleRedirect} className="flex items-center gap-4 p-2 hover:bg-gray-2/20 rounded-xl w-full">
      <div className="relative">
        <Avatar levelColor={likeAuthor?.LevelColor} className="h-16 w-16" image={likeAuthor?.Photo?.URL} />
        <div className="bg-white p-1.5 rounded-full absolute bottom-0 right-0 shadow-md">
          {isPositive ? <FaCheck className="text-primary" /> : <FaTimes className="text-red" />}
        </div>
      </div>
      <div className="flex flex-col gap-0 items-start">
        <CustomText weight={FontWeight.SemiBold}>{likeAuthor.Name}</CustomText>
        <CustomText size={TextSize.Caption}>
          {likeAuthor?.City ? `${likeAuthor?.City}, ` : ''}
          {likeAuthor?.Country || ''}
        </CustomText>
      </div>
    </button>
  )
}
