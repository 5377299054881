import clsx from 'clsx'
import { addDays, isBefore } from 'date-fns'
import Image from 'next/image'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import Divider from '@/components/base/Divider'
import { PostVoteCountSection } from '@/components/pages/groups/PostVoteCountSection'
import { useExchangeVote } from '@/hooks/groups/useExchangeVote'
import useRequest2 from '@/hooks/useRequest2'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import TokenFront from '@/public/static/images/token-front.png'
import { getExchangeVotes, getGroupExchangeVotes } from '@/services/groupServices'

export const GroupExchangeVote = ({ post, addon }) => {
  const { author } = useSunetAuthors()

  const [data, loading, error, revalidate] = useRequest2({
    requestKey: `/gamification/vote/${post.UUID}`,
    params: [author.UUID, addon.GroupRewardUUID],
    request: (a, g) => getExchangeVotes(a, g),
  })

  const createdAt = new Date(post.CreatedAt)
  const dayBeforeCreatedAt = addDays(createdAt, 1)

  const serverISOString = new Date().toISOString() // useServerTime()

  const canVote = isBefore(new Date(serverISOString), dayBeforeCreatedAt)

  const groupRewardUUID = addon.GroupRewardUUID

  const [votes, loadingVotes, votesError, revalidateVotes] = useRequest2({
    requestKey: `/exchange/votes/${groupRewardUUID}`,
    params: [groupRewardUUID],
    request: (groupRewardUUID) => getGroupExchangeVotes(groupRewardUUID),
  })

  const onSuccess = () => {
    revalidate()
    revalidateVotes()
  }

  const { handleVote, loading: loadingVote } = useExchangeVote({ onSuccess })

  const exchangeName = addon?.RewardName || ''
  const amount = addon?.TokensAmount ?? 0

  const handleExchangeVote = (v) => {
    handleVote({
      GroupRewardUUID: addon.GroupRewardUUID,
      IsPositive: v,
      AuthorUUID: author.UUID,
    })
  }

  const isResponded = data?.length === 1

  const isPositive = isResponded ? data[0].IsPositive : null
  return (
    <div className="bg-white border border-gray-200 rounded-2xl p-2 my-4 flex flex-col gap-4">
      <div className="flex items-start gap-4">
        <div className="w-[60px] h-[60px] sm:w-[60px] sm:h-[60px]">
          <Image
            className="rounded-xl h-full object-cover w-full"
            src={addon?.Image}
            width={100}
            height={100}
            alt="Exchange"
          />
        </div>
        <div className="flex flex-col py-2">
          <CustomText weight={FontWeight.SemiBold}>{exchangeName}</CustomText>
          <div className="flex items-center gap-2">
            <Image width={18} height={18} src={TokenFront} alt="SNT" />
            <CustomText weight={FontWeight.SemiBold}>
              {amount} <span className="font-normal text-sm">SNT</span>
            </CustomText>
          </div>
        </div>
      </div>
      <Divider />
      <div className="px-2">
        <PostVoteCountSection votes={votes} />
        {!isResponded && !canVote && <CustomText weight={FontWeight.SemiBold}>Ya pasaron 24 horas</CustomText>}
        {!isResponded && canVote && (
          <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap mt-2">
            <button
              disabled={loadingVote || loading}
              onClick={() => handleExchangeVote(true)}
              className="bg-primary border rounded-lg border-primary px-2 py-1 text-white flex items-center gap-2 w-full"
            >
              <FaCheckCircle className="text-white" />
              <CustomText size={TextSize.Caption} weight={FontWeight.Medium}>
                A favor
              </CustomText>
            </button>
            <button
              disabled={loadingVote || loading}
              onClick={() => handleExchangeVote(false)}
              className="bg-white border rounded-lg border-primary px-2 py-1 text-primary flex items-center gap-2 w-full"
            >
              <FaTimesCircle className="text-primary" />
              <CustomText size={TextSize.Caption} weight={FontWeight.Medium}>
                En contra
              </CustomText>
            </button>
          </div>
        )}
        {isResponded && (
          <CustomText weight={FontWeight.SemiBold} className={clsx(isPositive ? 'text-primary' : 'text-red')}>
            {isPositive ? 'Votaste a favor' : 'Votaste en contra'}
          </CustomText>
        )}
      </div>
    </div>
  )
}
